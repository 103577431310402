/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 12:14:21
 * @LastEditTime: 2021-01-21 18:09:54
 * @FilePath: \acuconference-web\src\mixins\conferenceInfoFilters.js
 */
export default {
  methods: {
    translateRoomType(type) {
      const roomTypeMap = {
        4: this.$t('label_select_room_type_video_call'),
        7: this.$t('label_select_room_type_conference')
      }
      return roomTypeMap[type]
    },
    translateConferenceMode(mode) {
      const conferenceModeMap = {
        1: this.$t('label_select_conference_type_host_control'),
        2: this.$t('label_select_conference_type_interactive_mode'),
        3: this.$t('label_select_conference_type_large_conference')
      }
      return conferenceModeMap[mode]
    }, 
    translateConferenceStatus(status) {
      const statusMap = {
        0: this.$t("label_conference_status_not_started"),
        1: this.$t("label_conference_status_in_progressing")
      }
      return statusMap[status]
    }
  }
}