<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 18:46:11
 * @LastEditTime: 2021-02-07 12:33:10
 * @FilePath: \acuconference-web\src\views\admin\liveSessions.vue
-->
<template>
  <div class="live-sessions-manager-wrapper">
    <v-app-bar
      dense
      style="flex-grow: unset"
      class="elevation-0 v-bar--underline"
    >
      <v-tabs
        :class="{
          'config-loading': loading,
        }"
        v-model="currentActiveTabIndex"
      >
        <v-tab v-for="tab in renderTabs" :key="tab.id">
          <span>{{ tab.i18n ? $t(tab.label) : tab.label }}</span>
          <v-btn
            style="margin-left: 10px"
            v-if="tab.canClose"
            icon
            x-small
            @click="closeActiveTab(tab)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-card style="height: 100%" :disabled="loading">
      <v-card-title v-if="currentActiveTabId === 'liveSessions'">
        <v-btn
          color="green"
          :title="$t('label_btn_refresh')"
          fab
          small
          :disabled="Boolean(refreshCount)"
          @click="refreshLiveSessions"
        >
          <v-icon color="white"> mdi-refresh </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          maxLength="500"
          append-icon="mdi-magnify"
          :label="$t('label_search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text v-show="currentActiveTabId === 'liveSessions'">
        <v-data-table
          :disable-sort="true"
          :locale="tableLocal"
          style="width: 100%"
          :items-per-page="limit"
          :headers="headers"
          :items="list"
          :page="page"
          :server-items-length="totalCount"
          @update:page="pageUpdate"
          @update:items-per-page="limitUpdate"
          :footer-props="tableFooterOptions"
        >
          <template v-slot:no-data>
            <span>
              {{ $t("table_no_data") }}
            </span>
          </template>
          <template v-slot:item.monitor="{ item }">
            <!-- <v-btn text color="primary" @click="setActiveTab(item)">
              {{ $t("label_control") }}
            </v-btn> -->
            <v-btn text color="primary" @click="terminationShow(item)">
              {{ $t("label_termination") }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text
        v-if="activeControllerPage"
        v-show="currentActiveTabId === 'controller'"
        style="height: 100%"
      >
        <iframe
          :src="activeControllerPage.link"
          class="monitor-page"
          frameborder="0"
        ></iframe>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import apis from "../../mixins/apis";
export default {
  mixins: [apis],
  data() {
    return {
      currentActiveTabIndex: 0,
      currentActiveTabId: "",
      limit: 10,
      list: [],
      page: 1,
      totalCount: 0,
      search: "",
      searchTimer: 0,
      liveSessionRoomStatus: 1,
      activeControllerPage: null,
      refreshCount: 0,
      refreshTimer: 0,
      iframId: null,
    };
  },
  computed: {
    nativeTabs() {
      return [
        {
          id: "liveSessions",
          label: this.$t("label_navigation_menu_list_live_sessions"),
        },
      ];
    },
    featureTabs() {
      const tab = [];
      if (this.activeControllerPage) {
        tab.push({
          id: "controller",
          label: this.activeControllerPage.name,
          canClose: true,
        });
      }
      return tab;
    },
    renderTabs() {
      return this.nativeTabs.concat(this.featureTabs);
    },
    headers() {
      return [
        {
          text: this.$t("label_conference_room"),
          value: "name",
          align: "center",
        },
        {
          text: this.$t("label_description"),
          value: "description",
          align: "center",
        },
        {
          text: this.$t("label_normal_Domain"),
          value: "domain",
          align: "center",
        },
        {
          text: this.$t("label_start_by"),
          value: "userAccount",
          align: "center",
        },
        {
          text: this.$t("label_user_count"),
          value: "onlineUserCount",
          align: "center",
        },
        {
          text: this.$t("label_monitor"),
          value: "monitor",
          align: "center",
        },
      ];
    },
  },
  watch: {
    currentActiveTabIndex: {
      immediate: true,
      handler(val) {
        this.currentActiveTabId = this.renderTabs[val].id;
      },
    },
    page: {
      immediate: true,
      handler() {
        this.initList();
      },
    },
    limit() {
      this.initList();
    },
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.initList();
        }
      }, 200);
    },
  },
  methods: {
    async terminationShow(item) {
      const result = await this.$confirm.warn(
        this.$t("msg_terminate_the_meeting"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_termination_ok"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      if (result === "confirm") {
        // 终止
        const result = await this.getTerminate(item.id);
        if (result) {
          this.$toast.success(this.$t("promp_msg"));
          this.$nextTick(() => {
            this.$emit("closeConferenceInfo", true);
          });
        }
      }
    },
    refreshLiveSessions() {
      clearInterval(this.refreshTimer);
      this.refreshCount = 3;
      this.refreshTimer = setInterval(() => {
        this.refreshCount -= 1;
        if (this.refreshCount <= 0) {
          this.refreshCount = 0;
          clearInterval(this.refreshTimer);
        }
      }, 1000);
      this.initList();
    },
    async initList() {
      this.list.length = 0;
      const params = {
        o: (this.page - 1) * this.limit,
        l: this.limit,
        s: this.liveSessionRoomStatus,
        k: encodeURIComponent(this.search),
      };
      const result = await this.getRoomsApi(params);
      if (result) {
        const { rooms, total } = result;
        let arr = rooms
        rooms.map((item) => {
          if (!item.onlineUserCount) {
            arr.push(Object.assign(item, { onlineUserCount: 0 }));
          }
        });
        this.list = [...new Set(arr)]; 
        this.totalCount = total;
      }
    },

    async setActiveTab(item) {
      if (this.iframId && this.iframId != item.id) {
        await this.closeActiveTab();
      }
      this.iframId = item.id;
      const result = await this.getParticipantsPage(item.id);
      if (result) {
        const { classUrl } = result;
        this.activeControllerPage = {
          name: item.name,
          link: classUrl,
        };
        this.$nextTick(() => (this.currentActiveTabIndex = 1));
      }
    },
    closeActiveTab() {
      this.currentActiveTabIndex = 0;
      this.$nextTick(() => (this.activeControllerPage = null));
    },
    pageUpdate(page) {
      process.env.NODE_ENV === "development" && console.error(page);
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
  },
};
</script>

<style lang="sass" scoped>
.live-sessions-manager-wrapper
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  .config-loading
    filter: grayscale(1)
    pointer-events: none
  .container
    position: relative
    flex-grow: 1
    width: 100%
    display: flex
    justify-content: center
    &>div
      width: 100%
.monitor-page
  width: 100%
  height: 100%
  left: 50%
</style>