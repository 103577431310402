<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 18:09:38
 * @LastEditTime: 2021-02-07 17:01:06
 * @FilePath: \acuconference-web\src\views\admin\conferenceList.vue
-->
<template>
  <div class="conference-list-wrapper">
    <v-app-bar
      dense
      style="flex-grow: unset"
      class="elevation-0 v-bar--underline"
    >
      <v-tabs
        :class="{
          'config-loading': loading,
        }"
        v-model="currentActiveTabIndex"
      >
        <v-tab v-for="tab in renderTabs" :key="tab.id">
          <span>{{ tab.i18n ? $t(tab.label) : tab.label }}</span>
          <v-btn
            style="margin-left: 10px"
            v-if="tab.canClose"
            icon
            x-small
            @click="closeActiveTab(tab)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-card
      :disabled="loading"
      :loading="loading"
      v-if="currentActiveTabId === 'conferenceList'"
    >
      <v-card-title>
        <v-btn
          :disabled="!selectColumn.length"
          color="error"
          fab
          small
          :title="$t('label_btn_delete')"
          @click="deleteSelectColumns"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
           <!--刷新 -->
        <v-btn
          color="green"
          :title="$t('label_btn_refresh')"
          fab
          small
          style="margin-left: 20px"
          :disabled="Boolean(refreshCount)"
          @click="refreshLiveSessions"
        >
          <v-icon color="white"> mdi-refresh </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          maxLength="500"
          append-icon="mdi-magnify"
          :label="$t('label_search')"
          single-line
          hide-details
        ></v-text-field>
     
      
      </v-card-title>
      <v-card-text>
        <v-data-table
          :disable-sort="true"
          :locale="tableLocal"
          style="width: 100%"
          :items-per-page="limit"
          :headers="headers"
          :items="list"
          :server-items-length="totalCount"
          @update:page="pageUpdate"
          show-select
          @update:items-per-page="limitUpdate"
          :page="page"
          :footer-props="tableFooterOptions"
          v-model="selectColumn"
        >
          <template v-slot:no-data>
            <span>
              {{ $t("table_no_data") }}
            </span>
          </template>
          <template v-slot:header.data-table-select>
            <v-simple-checkbox
              :value="!!selectColumn.length"
              @input="selectAll"
              :ripple="false"
            >
            </v-simple-checkbox>
          </template>
          <template
            v-slot:item.data-table-select="{ isSelected, select, item }"
          >
            <v-simple-checkbox
              :value="isSelected"
              @input="select"
              :ripple="false"
              v-if="item.status === 0"
            >
            </v-simple-checkbox>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip v-if="item.status" color="green" text-color="white">
              <v-icon left> mdi-play </v-icon>
              {{ translateConferenceStatus(item.status) }}
            </v-chip>
            <v-chip v-else text-color="white">
              {{ translateConferenceStatus(item.status)}}
            </v-chip>
          </template>
          <template v-slot:item.copy="{ item }">
            <v-btn text color="primary" @click="copyLink(item)">
              {{ $t("label_copy") }}
            </v-btn>
          </template>
          <template v-slot:item.inviteCode="{ item }">
            <div>
              <span>
                {{ item.inviteCode }}
              </span>
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <v-btn
              text
              color="primary"
              class="limit-btn"
              @click="showConferenceInfo(item)"
            >
              {{ item.name }}
            </v-btn>
          </template>
          <template v-slot:item.createTime="{ item }">
            <span>
              {{ formatTime(item.createTime) }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ConferenceInfo
      v-else-if="currentActiveTabId === 'conferenceInfo'"
      :conference="activeConference"
      @closeConferenceInfo="closeConferenceInfo"
    />
  </div>
</template>

<script>
import apis from "../../mixins/apis";
import formatTime from "../../mixins/timeFormatFilter";
import conferenceFilters from "../../mixins/conferenceInfoFilters";
import ConferenceInfo from "../../components/conferenceInfo";
export default {
  mixins: [apis, formatTime, conferenceFilters],
  components: { ConferenceInfo },
  data() {
    return {
      currentActiveTabIndex: 0,
      currentActiveTabId: "",
      selectColumn: [],
      search: "",
      searchTimer: 0,
      limit: 10,
      page: 1,
      activeConference: null,
      showConferenceInfoDialog: false,
      list: [],
      totalCount: 0,
      refreshTimer: 0,
      refreshCount: 0,
    };
  },
  computed: {
    featureTabs() {
      const tab = [];
      if (this.activeConference) {
        tab.push({
          id: "conferenceInfo",
          canClose: true,
          label: this.$t("label_title_conference_information"),
        });
      }
      return tab;
    },
    nativeTabs() {
      const tab = [
        {
          id: "conferenceList",
          label: this.$t("label_tab_conferences"),
        },
      ];
      return tab;
    },
    renderTabs() {
      return this.nativeTabs.concat(this.featureTabs);
    },
    headers() {
      return [
        {
          text: this.$t("label_conference_room"),
          value: "name",
          align: "center",
        },
        {
          text: this.$t("label_conference_invitation_code"),
          value: "inviteCode",
          align: "center",
        },
        {
          text: this.$t("label_copy_link"),
          value: "copy",
          align: "center",
        },
        {
          text: this.$t("label_description"),
          value: "description",
          align: "center",
          cellClass: "max-width"
        },
        {
          text: this.$t("label_status"),
          value: "status",
          align: "center",
        },
        {
          text: this.$t("label_normal_Domain"),
          value: "domain",
          align: "center",
        },
        {
          text: this.$t("label_normal_account"),
          value: "userAccount",
          align: "center",
        },
        {
          text: this.$t("label_created"),
          value: "createTime",
          align: "center",
        },
      ];
    },
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getRooms();
      },
    },
    limit() {
      this.getRooms();
    },
    currentActiveTabIndex: {
      immediate: true,
      handler(val) {
        this.currentActiveTabId = this.renderTabs[val].id;
      },
    },
    showConferenceInfoDialog(val) {
      if (!val && this.activeConference) {
        this.activeConference = null;
      }
    },
    activeConference(val) {
      if (val) {
        this.showConferenceInfoDialog = true;
      }
    },
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.getRooms();
        }
      }, 200);
    },
  },
  methods: {
    //刷新
    refreshLiveSessions() {
      clearInterval(this.refreshTimer);
      this.refreshCount = 3;
      this.refreshTimer = setInterval(() => {
        this.refreshCount -= 1;
        if (this.refreshCount <= 0) {
          this.refreshCount = 0;
          clearInterval(this.refreshTimer);
        }
      }, 1000);
      this.getRooms();
    },
    async getRooms() {
      //  items per page will be -1 if user select "All"
      const limit = this.limit;
      const offset = (this.page - 1)  * limit;
      const result = await this.getRoomsApi({
        o: offset,
        l: limit,
        k: encodeURIComponent(this.search),
      });
      const { rooms, total } = result;
      this.list = rooms;
      this.totalCount = total;
    },
    pageUpdate(page) {
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
    copyLink(item) {
      const link = location.origin + "/#/joinmeeting/" + item.inviteCode;
      process.env.NODE_ENV === "development" && console.log(link);
      if (this.$utils.copyText(link)) {
        this.$toast.success(this.$t("prompt_success"));
      }
    },
    selectAll(toggle) {
      this.selectColumn = [];
      if (toggle) {
        for (let index = 0; index < this.list.length; index++) {
          if (this.list[index].status === 0) {
            this.selectColumn.push(this.list[index]);
          }
        }
      }
    },
    showConferenceInfo(conference) {
      this.activeConference = conference;
      this.$nextTick(() => {
        this.currentActiveTabIndex = 1;
      });
    },
    closeActiveTab() {
      this.currentActiveTabIndex = 0;
      this.$nextTick(() => {
        this.activeConference = null;
      });
    },
    closeConferenceInfo(needRefresh) {
      this.showConferenceInfoDialog = false;
      if (needRefresh) {
        this.getRooms();
      }
    },
    async deleteSelectColumns() {
      const confirm = await this.$confirm.warn(
        this.$t("label_delete_confirm"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_btn_confirm"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      if (confirm === "confirm") {
        const ids = this.selectColumn.map((item) => {
          return item.id;
        });
        const result = await this.deleteConference({
          ids,
        });
        if (result) {
          this.selectColumn = [];
          this.$toast.success(this.$t("prompt_success"));
          this.getRooms();
        }
      }
    },
  },
};
</script>

<style lang="sass">
.conference-list-wrapper
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  .config-loading
    filter: grayscale(1)
    pointer-events: none
  .container
    position: relative
    flex-grow: 1
    width: 100%
    display: flex
    justify-content: center
    &>div
      width: 100%

.max-width
  max-width: 300px
</style>