<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 11:26:27
 * @LastEditTime: 2021-02-07 11:48:01
 * @FilePath: \acuconference-web\src\components\conferenceInfo.vue
-->
<template>
  <v-card height="100%" :loading="loading" :disabled="loading">
    <v-card-title class="justify-center">
      {{ $t("label_title_conference_information") }}
    </v-card-title>
    <v-card-text>
      <v-row align="center" v-for="(item, index) in renderItems" :key="index">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3" style="margin-bottom:10px;">
          {{ $t(item.label) }}
        </v-col>
        <v-col cols="12" sm="3" v-if="!item.type" style="margin-bottom:10px;">
          {{ item.value }}
        </v-col>
        <v-col cols="12" sm="3" v-else-if="item.type === 'checkbox'">
          <v-checkbox v-model="item.value" :disabled="true" style="height:25px;margin-top:-5px;"> </v-checkbox>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-btn color="red" @click="confirmDelete" dark>
        <v-icon left color="white"> mdi-delete </v-icon>
        {{ $t("label_btn_delete") }}
      </v-btn>
      <v-btn color="primary" dark @click="showEditConferenceDialog = true">
        <v-icon left> mdi-pencil </v-icon>
        {{ $t("label_btn_modify") }}
      </v-btn>
    </v-card-actions>
    <EditConferenceDialog
      v-model="showEditConferenceDialog"
      :conference="renderConference"
      :enterprise="enterpriseInfo"
      @finishEditConference="refreshCurrentConferenceInfo"
      @afterDelete="closeInfo"
    />
  </v-card>
</template>

<script>
import apis from "../mixins/apis";
import ConferenceInfoFilter from "../mixins/conferenceInfoFilters";
import EditConferenceDialog from "./dialogs/editConference";
export default {
  mixins: [apis, ConferenceInfoFilter],
  components: { EditConferenceDialog },
  props: {
    conference: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    conferenceMode() {
      if (this.renderConference.roomInfo.conferenceModes) {
        const modes = this.renderConference.roomInfo.conferenceModes;
        return this.translateConferenceMode(modes[0]);
      } else {
        return this.translateConferenceMode(2);
      }
    },
    nativeItems() {
      return [
        {
          label: "label_conference_room",
          value: this.renderConference.name,
        },
        {
          label: "label_account",
          value: this.renderConference.userAccount,
        },
        {
          label: "label_status",
          value: this.translateConferenceStatus(this.renderConference.status),
        },
        {
          label: "label_description",
          value: this.renderConference.description,
        },
        {
          label: "label_input_label_max_participants",
          value:
            typeof this.renderConference.maxParticipants === "number"
              ? this.renderConference.maxParticipants
              : this.enterpriseInfo.maxParticipants,
        },
        {
          label: "label_conference_mode",
          value: this.conferenceMode,
        },
        {
          label: "label_input_label_enable_conference_record",
          value: this.renderConference.enableRecord,
          type: "checkbox",
        },
        {
          label:"label_input_label_enable_waiting_room",
          value:this.renderConference.waitingRoom,
          type: "checkbox",
        }
      ];
    },
    renderItems() {
      const nativeItems = this.nativeItems;
      const renderItems = nativeItems.filter(
        (item) => item.value !== undefined
      );
      return renderItems;
    },
  },
  methods: {
    closeInfo() {
      this.$nextTick(() => {
        this.$emit("closeConferenceInfo", true);
      });
    },
    async confirmDelete() {
      const result = await this.$confirm.warn(this.$t("label_delete_confirm"), {
        title: this.$t("label_warn"),
        buttons: [
          {
            label: this.$t("label_btn_cancel"),
            value: "cancel",
            color: "primary",
          },
          {
            label: this.$t("label_btn_confirm"),
            value: "confirm",
            color: "primary",
          },
        ],
      });
      if (result === "confirm") {
        // do delete
        const result = await this.deleteConference({
          ids: [this.conference.id],
        });
        if (result) {
          this.$toast.success(this.$t("prompt_success"));
          this.$nextTick(() => {
            this.$emit("closeConferenceInfo", true);
          });
        }
      }
    },
    async refreshCurrentConferenceInfo() {
      const result = await this.getConferenceInfoApi(this.conference.id);
      if (result) {
        this.renderConference = result;
      }
    },
    async getEnterpriseInfo() {
      const result = await this.getEnterpriseById(
        this.renderConference.enterpriseId
      );
      if (result) {
        this.enterpriseInfo = result;
      }
    },
  },
  data() {
    return {
      renderConference: { roomInfo: {} },
      showEditConferenceDialog: false,
      enterpriseInfo: {},
    };
  },
  created() {
    this.renderConference = Object.assign(
      {},
      JSON.parse(JSON.stringify(this.conference)),
      this.conference.roomInfo ? {} : { roomInfo: {} }
    );
    if (this.renderConference.enterpriseId) {
      this.getEnterpriseInfo();
      this.refreshCurrentConferenceInfo()
    }
  },
};
</script>

<style lang="sass" scoped>

</style>